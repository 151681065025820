import { propertyShorthands } from 'modules/style/property-shorthands'
import {
  legacyGridValue as grid,
  makeResponsiveSpace,
  makeResponsiveSpaceChildren,
  space as makeSpace,
  spaceChildren as makeSpaceChildren,
  unit as makeUnit,
  units as makeUnits,
} from 'modules/style/space'
import { media } from './media.legacy'

export { grid }

export const units = value => makeUnits(value, { grid })

export const unit = value => makeUnit(value, { grid })

export const space = (properties, unit, template) => makeSpace(properties, unit, template, { grid })

export const spaceChildren = (property, units, configOrTemplate, configIn = {}) =>
  makeSpaceChildren(property, units, configOrTemplate, { ...configIn, grid })

const breakpointValues = [
  ['default', 1.5],
  ['medium', 2],
  ['large', 2.5],
  ['xlarge', 3],
]

export const responsiveSpace = makeResponsiveSpace({ breakpointValues, grid, media })

export const responsiveSpaceChildren = makeResponsiveSpaceChildren({
  breakpointValues,
  grid,
  media,
})

for (const key in propertyShorthands) {
  space[key] = (units, template) => space(key, units, template, { grid })
  spaceChildren[key] = (units, template) => spaceChildren(key, units, template, { grid })
  responsiveSpace[key] = units => responsiveSpace(key, units)
  responsiveSpaceChildren[key] = units => responsiveSpaceChildren(key, units)
}
