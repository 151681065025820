import * as S from 'apps/cliniko/style'
import Portal from 'components/portal.legacy'
import Transition from 'components/transition'
import * as D from 'modules/document'
import * as E from 'modules/element'
import { useModalIsOpen } from 'modules/modal'
import * as _ from 'modules/util'
import { useEffect, useRef } from 'react'

const Modal = ({ as = 'section', children, className, onDismiss, ...props }) => {
  const previouslyFocusedElement = useRef()
  const modalRef = useRef()
  const trapFocusRef = E.useTrapFocus()
  const dismissible = Boolean(onDismiss)
  const [, setModalIsOpen] = useModalIsOpen()
  const ref = _.useMergeRefs([modalRef, trapFocusRef])

  useEffect(() => {
    previouslyFocusedElement.current = document.activeElement
    modalRef.current?.focus()
    setModalIsOpen(true)

    return () => {
      setModalIsOpen(false)
      previouslyFocusedElement.current?.focus()
    }
  }, [setModalIsOpen])

  D.useKeyDownMatching(
    { key: 'Escape' },
    event => (_.isFromInput(event) ? event.target.blur() : onDismiss()),
    { when: dismissible }
  )

  E.useClickedOutside(modalRef, onDismiss, { when: dismissible })

  return (
    <Portal>
      <Transition
        className={className}
        css={modalStyles}
        as={as}
        duration={100}
        enter={{ opacity: 1 }}
        from={{ opacity: 0 }}>
        <div
          css={{ '[data-focus-visible-added]': { outline: 'none' } }}
          ref={ref}
          role="dialog"
          tabIndex="-1"
          {...props}>
          {children}
        </div>
      </Transition>
    </Portal>
  )
}

const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: '#000000',
  webkitTapHighlightColor: 'transparent',
  webkitOverflowScrolling: 'touch',
  overflowY: 'scroll',
  zIndex: S.zIndex('rootElements', 'modal'),

  [S.legacy.media.large]: {
    overflow: 'auto',
    background: 'rgba(0, 0, 0, 0.8)',
  },
}

export default Modal
