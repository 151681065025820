import * as S from 'apps/cliniko/style'
import Heading from 'components/heading.legacy'

const Header = ({ className, children, title }) => (
  <header className={className} css={styles}>
    <Heading level={1}>{title}</Heading>
    {children}
  </header>
)

const styles = {
  padding: S.rem(20),
  background: S.legacy.colors.primary,
  color: 'white',

  [S.legacy.media.medium]: {
    paddingTop: S.rem(30),
    paddingRight: S.rem(75),
    paddingBottom: S.rem(30),
    paddingLeft: S.rem(30),
  },

  [S.legacy.media.large]: {
    paddingRight: S.rem(30),
  },
}

export default Header
