import * as R from 'ramda'

export const hrefIsRoutable = R.either(R.startsWith('/'), R.startsWith('#'))

export const hrefParse = href => {
  const url = new URL(href, window.location.origin)
  const isHashOnly = R.startsWith('#', href)
  return {
    hash: url.hash,
    host: url.host,
    path: isHashOnly ? '' : url.pathname,
    search: isHashOnly ? '' : url.search,
  }
}

const isInternal = href =>
  !href || hrefParse(href).host === window.location.host || /^(tel:|mailto:)/.test(href)

export const isExternal = R.complement(isInternal)
