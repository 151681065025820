import * as S from 'apps/cliniko/style'
import * as R from 'ramda'

const Heading = ({ as, level = 3, styleLevel = level, ...props }) => {
  const Component = R.or(as, `h${level}`)
  return <Component css={[sharedStyles, R.prop(styleLevel, stylesByLevel)]} {...props} />
}

export const sharedStyles = {
  position: 'relative',
}

const stylesByLevel = {
  1: {
    fontSize: S.rem(24),
    fontFamily: 'roboto-slab-light, sans-serif',
    fontWeight: 'bold',
    lineHeight: S.rem(30),
    bottom: S.rem(-6),
    marginTop: S.rem(-9),
  },
  2: {
    fontSize: S.rem(21),
    fontFamily: 'roboto-slab-light, sans-serif',
    fontWeight: 600,
    lineHeight: S.rem(20),
    bottom: S.rem(-1),
    marginTop: S.rem(-1.5),
  },
  3: {
    fontSize: S.rem(18),
    fontWeight: 600,
    lineHeight: S.rem(20),
    bottom: S.rem(-2),
    marginTop: S.rem(-3),
  },
  4: {
    fontSize: S.rem(16),
    lineHeight: S.rem(20),
    fontWeight: 'bold',
  },
}

export default Heading
