import Honeybadger from '@honeybadger-io/js'
import * as _ from 'modules/util'
import * as R from 'ramda'

Honeybadger.configure({
  apiKey: window.HONEYBADGER_API_KEY,
  enableUncaught: false,
  environment: window.environment,
  revision: window.version,
})

Honeybadger.setContext({
  type: 'javascript',
  account_id: window.account_id,
  react: true,
  shard: window.CLINIKO_SHARD,
})

Honeybadger.removeContext = keys => Honeybadger.setContext(R.omit(keys, Honeybadger.context))

export const notify = (error, context) => !_.isTest() && Honeybadger.notify(error, { context })

export default Honeybadger
