// values in unitless pixels, convert to em/rem as needed
export const small = 400
export const medium = 600
export const large = 826
export const xlarge = 1050 // if changed check invoice show header with all 7 buttons
export const xxlarge = 1150 // if changed check treatment notes
export const xxxlarge = 1300
export const xxxxlarge = 1700

export const allBreakpoints = { small, medium, large, xlarge, xxlarge, xxxlarge, xxxxlarge }

export const inBetweenBreakpoints = [
  ['small', 'medium'],
  ['small', 'large'],
  ['medium', 'large'],
  ['medium', 'xlarge'],
  ['large', 'xlarge'],
  ['large', 'xxlarge'],
]
