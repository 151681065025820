import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'
import { em } from './units'

export const makeBreakpoint = ({ allBreakpoints, media }) => {
  const breakpoint = R.curry((bp, value) => {
    const mediaQuery = _.cond(
      [
        _.isNumber(bp),
        `@media screen and (${bp < 0 ? 'max' : 'min'}-width: ${em(bp < 0 ? bp * -1 - 1 : bp)})`,
      ],
      [_.isString(bp), media[bp]],
      [
        _.isArray(bp) && L.and([L.elems, _.isNumber], bp),
        `@media screen and (min-width: ${em(bp[0])}) and (max-width: ${em(bp[1] - 1)})`,
      ],
      [
        _.isArray(bp) && L.and([L.elems, _.isString], bp),
        `@media screen and (min-width: ${em(allBreakpoints[bp[0]])}) and (max-width: ${em(
          allBreakpoints[bp[1]] - 1
        )})`,
      ]
    )

    return bp === 'never' ? value : { [mediaQuery]: value }
  })

  const breakpoints = {
    below: R.curry((bp, value) => {
      if (bp === 'never') return

      return breakpoint(
        _.thru(
          bp,
          R.ifElse(_.isString, R.pipe(_.capitalizeFirstLetter, R.concat('below')), R.negate)
        ),
        value
      )
    }),
    on: breakpoint,
  }

  for (const query in media) {
    breakpoints[query] = breakpoint(query)
  }

  return breakpoints
}
