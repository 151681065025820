import * as _ from 'modules/util'
import * as R from 'ramda'

export const propertyShorthands = {
  m: ['margin'],
  mt: ['marginTop'],
  mr: ['marginRight'],
  mb: ['marginBottom'],
  ml: ['marginLeft'],
  mx: ['marginRight', 'marginLeft'],
  my: ['marginTop', 'marginBottom'],
  p: ['padding'],
  pt: ['paddingTop'],
  pr: ['paddingRight'],
  pb: ['paddingBottom'],
  pl: ['paddingLeft'],
  px: ['paddingRight', 'paddingLeft'],
  py: ['paddingTop', 'paddingBottom'],
}

const expand = property => propertyShorthands[property] || [property]

export const expandProperties = R.pipe(R.ifElse(_.isArray, R.map(expand), expand), R.flatten)
