// Note: Once removing Angular we should import icons as svg's with react-svg-loader and remove this
import * as icons from '../../app/assets/javascripts/angular/shared/components/icons/index'

const Icon = ({ circle: _circle, name, ...props }) => {
  const svg = (
    <svg
      dangerouslySetInnerHTML={{ __html: icons[name] }}
      role="presentation"
      viewBox="0 0 1024 1024"
      {...props}
    />
  )

  return svg
}

export default Icon
