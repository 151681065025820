import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'
import { expandProperties, propertyShorthands } from './property-shorthands'
import { rem } from './units'

// Main grid value in unitless px. Changes here will cascade everywhere
export const gridValue = 8
export const legacyGridValue = 10
const defaultConfig = { grid: gridValue }

// takes a grid unit value and returns unitless px
export const units = (value, config = defaultConfig) => {
  if (_.isNumber(value)) {
    return value * config.grid
  }
}

// takes a grid unit value and returns value in rem
export const unit = (value, config = defaultConfig) => rem(units(value, config))

export const space = (properties, units = 1, template = R.identity, config = defaultConfig) => {
  const deepMergeFold = R.reduce(R.mergeDeepRight, {})

  const objectToRule = R.pipe(
    R.mapObjIndexed((val, key) => space(expandProperties(key), val, undefined, config)),
    R.values
  )

  const makeRule = property => _.thru(unit(units, config), template, R.objOf(property))

  const makeStyleFromArray = R.pipe(
    expandProperties,
    R.reject(_.isBoolean),
    R.reject(_.isBlank),
    R.map(makeRule),
    deepMergeFold
  )

  const makeStyleFromObject = R.pipe(objectToRule, deepMergeFold)

  const makeStyle = R.ifElse(_.isObject, makeStyleFromObject, makeStyleFromArray)

  return makeStyle(properties)
}

export const spaceChildren = (property, units, configOrTemplate, configIn) => {
  const template = _.isFunction(configOrTemplate) ? configOrTemplate : R.identity
  const config = configIn || configOrTemplate
  const lastChildUnits = unit(config?.lastChild || 0, configIn?.grid && { grid: configIn.grid })

  return R.mergeAll([
    {
      '& > *': _.thru(
        _.isObject(property)
          ? space(property, undefined, undefined, configIn)
          : space(property, units, template, configIn),
        R.map(R.concat(R.__, ' !important'))
      ),
    },
    {
      '& > *:last-child': _.isString(property)
        ? _.thru(
            property,
            expandProperties,
            R.map(prop => R.objOf(prop, `${lastChildUnits} !important`)),
            R.mergeAll
          )
        : _.thru(
            property,
            R.when(_.isObject, R.pipe(R.keys, expandProperties)),
            R.reduce(
              (acc, prop) =>
                L.set(propertyShorthands[prop] || prop, `${lastChildUnits} !important`, acc),
              {}
            )
          ),
    },
  ])
}

const makeResponsiveHelper =
  helper =>
  ({ breakpointValues, grid, media }) =>
  (properties, rhythmUnits = 1, template = R.identity) =>
    _.thru(
      breakpointValues,
      R.map(([breakpointName, units]) => {
        const helperResult = helper(properties, units * rhythmUnits, template, { grid })
        return breakpointName === 'default'
          ? helperResult
          : R.objOf(R.prop(breakpointName, media), helperResult)
      })
    )

export const makeResponsiveSpace = makeResponsiveHelper(space)
export const makeResponsiveSpaceChildren = makeResponsiveHelper(spaceChildren)

for (const key in propertyShorthands) {
  space[key] = (units, template, config) => space(key, units, template, config)
  spaceChildren[key] = (units, template, config) => spaceChildren(key, units, template, config)
}
