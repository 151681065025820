import * as _ from 'modules/util'
import { useCallback, useLayoutEffect, useState } from 'react'

export const useMatchMedia = query => {
  const matchMediaQuery = window.matchMedia(query)
  const supportsMatchMediaListener = Object.hasOwnProperty.call(matchMediaQuery, 'addEventListener')

  const [matches, setMatches] = useState(matchMediaQuery.matches)
  const doSetMatches = useCallback(
    () => setMatches(matchMediaQuery.matches),
    [matchMediaQuery.matches]
  )

  useLayoutEffect(doSetMatches, [doSetMatches])
  _.useEventListener('change', doSetMatches, {
    ref: matchMediaQuery,
    when: supportsMatchMediaListener,
  })

  // support Safari < 14
  useLayoutEffect(() => {
    if (supportsMatchMediaListener) return

    matchMediaQuery.addListener(doSetMatches)
    return () => {
      matchMediaQuery.removeListener(doSetMatches)
    }
  }, [matchMediaQuery, doSetMatches, supportsMatchMediaListener])

  return matches
}
