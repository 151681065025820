import * as _ from 'modules/util'
import * as R from 'ramda'

export const makeRelativeValue = unit => value =>
  _.isNumber(value) ? value / 16 + unit : undefined

export const em = makeRelativeValue('em')
export const toEm = em

export const rem = makeRelativeValue('rem')
export const toRem = rem

export const percent = R.pipe(_.toString, R.concat(R.__, '%'))
export const toPercent = percent
