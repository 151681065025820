import { CatchBoundary } from '@tanstack/react-router'
import { notify as notifyHoneybadger } from 'apps/error-reporting'
import * as _ from 'modules/util'

const ErrorBoundary = ({ fallback, notify = notifyHoneybadger, onError = () => {}, ...props }) => {
  _.throwUnless(fallback, 'A fallback component is required.')

  return (
    <CatchBoundary
      errorComponent={fallback}
      getResetKey={() => 'reset'}
      onCatch={_.actions(notify, onError)}
      {...props}
    />
  )
}

export default ErrorBoundary
