// fonts
export const bodyFont = '#333333'
export const bodyFontLight = '#767676'
export const headingFont = '#4B384C'

// primary blue
export const primary = '#007FA3'
export const primaryLighter = '#18ADD8'
export const primaryLightest = '#BADEE8'
export const primaryShade = '#007799'
export const primaryDark = '#006B8A'
export const primaryDarkShade = '#00637F'
export const primaryDarker = '#005770'
export const primaryPale = '#DEEEF3'
export const primaryPaler = '#D4E5ED'
export const primaryPaleLine = '#B9DBE5'

// secondary darks and greys
export const secondary = '#4B384C'
export const secondaryLight = '#595959'
export const secondaryLighter = '#A6A6A6'
export const secondaryLighterStill = '#B3B3B3'
export const secondaryMuchLighter = '#EBEBEB'
export const secondaryLightest = '#F7F7F7'
export const darkerShadeOfSecondaryLightest = '#F0F0F0'
export const secondaryDark = '#453446'
export const secondaryDarker = '#3F2F40'

export const muted = '#C3BDC3'

// tertiary yellow
export const tertiary = '#FCF0C7'
export const tertiaryLine = '#F6D04E'

// Action red
export const action = '#CC3057'
export const actionBorder = '#9A2442'
export const actionHover = '#AF294B'
export const actionLight = '#DC6C88'
export const actionLighter = '#EBAABA'
export const actionLightest = '#F5D3DC'

// lines
export const inputLine = '#AFB0B2'
export const line = '#CCCCCC'
export const lineDark = '#AFB0B2'
export const lineLight = '#E5E5E5'

// states
export const error = '#CB4141'
export const errorBorder = '#A22C2C'
export const errorDark = '#C23535'
export const errorLight = '#CB414150'
export const errorLine = '#6E1E1E'
export const errorShade = '#C23535'
export const warning = '#F8E08E'
export const warningBorder = '#F2C62E'
export const warningDark = '#F4CC46'
export const warningLight = '#FFF8E3'
export const warningLine = '#F1BF16'
export const warningShade = '#F6D871'
export const warningSvg = '#DFB00E'
export const success = '#91D6AC'
export const successDark = '#47BA74'
export const successLine = '#3FA968'
export const successLight = '#DBF2E4'
export const successLighter = '#EEFAF2'
export const successShade = '#6CC890'
export const successLighterShade = '#82D0A1'

// other
export const white = '#F5FBFF'
