import * as S from 'apps/cliniko/style'

const Prose = ({ as: Component = 'p', lead, ...props }) => (
  <Component css={styles({ lead })} {...props} />
)

export const lineHeightPx = 22
export const lineHeight = S.rem(lineHeightPx)

const styles = ({ lead }) => ({
  color: 'inherit',
  fontSize: !!lead && S.rem(18),
  lineHeight,
  marginBottom: 0,
})

export default Prose
