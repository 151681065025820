import { Global, css } from '@emotion/react'
import * as S from 'apps/cliniko/style'

const Loader = ({ className, inverse }) => (
  <div className={className} css={styles({ inverse })} role="alert" aria-busy="true">
    <Global styles={bounceKeyframeStyles} />
    <div />
    <div />
    <div />
  </div>
)

const bounceKeyframeStyles = css`
  @keyframes single-bounce {
    0% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
    10% {
      transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -5px, 0);
      opacity: 0.7;
    }
    20% {
      transform: translate3d(0, 0, 0);
    }
  }
`

const styles = ({ inverse }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  margin: '0 auto',
  width: S.rem(32),

  '& > *': {
    animation: 'single-bounce 1s ease-out 0s infinite',
    animationFillMode: 'both',
    width: S.rem(8),
    height: S.rem(8),
    borderRadius: '50%',
    background: inverse ? S.legacy.colors.secondaryLight : S.legacy.colors.secondaryMuchLighter,

    '&:nth-of-type(2)': {
      animationDelay: '0.3s',
      marginRight: S.rem(4),
      marginLeft: S.rem(4),
    },

    '&:nth-of-type(3)': {
      animationDelay: '0.6s',
    },
  },
})

export default Loader
