import { Link as RouterLink } from '@tanstack/react-router'
import * as S from 'apps/cliniko/style'
import { hrefIsRoutable, hrefParse } from 'modules/router'
import * as _ from 'modules/util'
import { useState } from 'react'

const Link = ({ children, className, href, plain, rel, reload, target, ...propsIn }) => {
  const [isAngularApp] = useState(!!document.querySelector('[ng-app="cliniko"]'))

  const isExternal = hrefParse(href).host !== window.location.host

  const props = {
    css: styles({ plain }),
    className,
    rel: _.joinStrings(isExternal && 'nofollow noopener noreferrer', rel),
    target: target || _.when(isExternal, '_blank'),
    ...propsIn,
  }

  return !reload && hrefIsRoutable(href) && !isAngularApp ? (
    <RouterLink data-testid="router-link" to={href} {...props}>
      {children}
    </RouterLink>
  ) : (
    <a href={href} data-testid="a-link" {...props}>
      {typeof children === 'function' ? children() : children}
    </a>
  )
}

const styles = ({ plain }) => ({
  color: plain ? 'inherit' : S.legacy.colors.action,
  textDecoration: plain ? 'none' : 'underline',

  '&:hover': {
    textDecoration: plain ? 'none' : 'underline',
  },

  '&[data-focus-visible-added]': {
    outline: `1px dashed ${S.legacy.colors.action}`,
    outlineOffset: S.rem(2),
  },
})

export default Link
