import { useEffectEvent } from 'modules/util/react'
import { useEffect } from 'react'

export const useInterval = (callback, delay, { when = true } = {}) => {
  const onTick = useEffectEvent(callback)

  useEffect(() => {
    if (!when) return
    const id = setInterval(onTick, delay)
    return () => clearInterval(id)
  }, [delay, onTick, when])
}
