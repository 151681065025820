import * as _ from 'modules/util'
import * as W from 'modules/window'
import * as R from 'ramda'
import { toEm } from './units'

export const makeMatchBreakpoint = ({ allBreakpoints }) => {
  const getBreakpointValue = R.prop(R.__, allBreakpoints)

  const getBreakpointWidth = R.cond([
    [_.isString, getBreakpointValue],
    [_.isNumber, R.identity],
    [_.isArray, R.map(R.either(getBreakpointValue, R.identity))],
  ])

  const makeUseBreakpointHelper = template => R.pipe(getBreakpointWidth, template, W.useMatchMedia)

  const useIsPastBreakpoint = makeUseBreakpointHelper(width => `(min-width: ${toEm(width)})`)

  const useIsBelowBreakpoint = makeUseBreakpointHelper(
    width => `(max-width: ${_.thru(width, R.dec, toEm)})`
  )

  const useIsBetweenBreakpoints = makeUseBreakpointHelper(
    ([width1, width2]) =>
      `(min-width: ${toEm(width1)}) and (max-width: ${_.thru(width2, R.dec, toEm)})`
  )

  return { useIsPastBreakpoint, useIsBelowBreakpoint, useIsBetweenBreakpoints }
}
