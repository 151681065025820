import { rem } from 'modules/style/units'
import { space } from './space'

export const flexWrap = ({ ml = 1, mt = 1 } = {}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'flex-start',
  ...space({ mt: mt * -1, ml: ml * -1 }),

  '& > *': space({ mt, ml }),
})

export const buttonPadding = ({ compact } = {}) => ({
  paddingTop: rem(6),
  paddingBottom: rem(5),
  paddingLeft: rem(compact ? 10 : 20),
  paddingRight: rem(compact ? 10 : 20),
  minHeight: rem(35),
  lineHeight: rem(22),
})
