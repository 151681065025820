import * as D from 'modules/document'
import * as _ from 'modules/util'
import * as R from 'ramda'

export const useClickedOutside = (elements, callback, options) =>
  D.useMouseDown(
    R.unless(
      event =>
        _.castArray(elements).some(el => {
          const container = Object.prototype.hasOwnProperty.call(el, 'current') ? el.current : el
          if (!container) return true
          return container.contains(event.target)
        }),
      callback
    ),
    options
  )
