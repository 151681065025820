import * as S from 'apps/cliniko/style'
import Button from 'components/button.legacy'

const CloseButton = props => (
  <Button
    aria-label="Close"
    css={buttonStyles}
    icon={{ name: 'mega-cross', styles: iconStyles }}
    {...props}
  />
)

const buttonStyles = {
  position: 'absolute',
  zIndex: S.zIndex('rootElements', 'super'),
  top: S.rem(15),
  right: S.rem(15),
  padding: S.rem(5),
  background: 'none',
  border: 0,

  [S.legacy.media.medium]: {
    top: S.rem(22),
    right: S.rem(25),
  },

  [S.legacy.media.large]: {
    top: S.rem(20),
    right: S.rem(20),
    padding: S.rem(15),
  },

  '&[data-focus-visible-added]': {
    outlineStyle: 'dashed',
    outlineColor: 'white',
  },

  '&:hover': {
    background: 'transparent',
  },
}

const iconStyles = {
  top: S.rem(2),
  position: 'relative',
  fill: S.legacy.colors.white,
  height: S.rem(20),
  width: S.rem(20),
}

export default CloseButton
