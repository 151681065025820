import * as _ from 'modules/util'
import * as R from 'ramda'
import { toEm } from './units'

export const makeMediaQueries = ({ allBreakpoints, inBetweenBreakpoints }) => {
  const media = {}

  media.print = '@media print'

  const getBreakpoint = R.prop(R.__, allBreakpoints)

  // min-width queries: small, large, etc
  for (const breakpoint in allBreakpoints) {
    media[breakpoint] = `@media screen and (min-width: ${_.thru(breakpoint, getBreakpoint, toEm)})`
  }

  // max-width queries: belowSmall, belowLarge, etc
  for (const breakpoint in allBreakpoints) {
    const name = `below${_.capitalizeFirstLetter(breakpoint)}`
    media[name] = `@media screen and (max-width: ${_.thru(breakpoint, getBreakpoint, R.dec, toEm)})`
  }

  // min-width and max-width queries: betweenSmallAndLarge, betweenMediumAndLarge, etc
  const makeInBetweenMediaQuery = ([bp1, bp2]) => {
    const name = `between${_.capitalizeFirstLetter(bp1)}And${_.capitalizeFirstLetter(bp2)}`
    const minWidthQuery = `(min-width: ${_.thru(bp1, getBreakpoint, toEm)})`
    const maxWidthQuery = `(max-width: ${_.thru(bp2, getBreakpoint, R.dec, toEm)})`
    media[name] = `@media screen and ${minWidthQuery} and ${maxWidthQuery}`
  }

  if (inBetweenBreakpoints) inBetweenBreakpoints.forEach(makeInBetweenMediaQuery)

  return media
}
