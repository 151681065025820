import { ModalContext } from 'modules/contexts'
import { useContext, useState } from 'react'

export const ModalProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <ModalContext.Provider value={{ modalIsOpen, setModalIsOpen }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalIsOpen = () => {
  const { modalIsOpen, setModalIsOpen } = useContext(ModalContext)
  return [modalIsOpen, setModalIsOpen]
}
