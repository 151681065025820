import { animated, easings, useTransition } from '@react-spring/web'
import { forwardRef } from 'react'

const Transition = forwardRef(
  (
    {
      as = 'div',
      config = { tension: 125, friction: 20 },
      delay,
      duration,
      easing = easings.easeInOutCubic,
      enter,
      from,
      leave,
      onDestroyed,
      when = true,
      ...props
    },
    ref
  ) => {
    const transitions = useTransition(when, {
      config: duration ? { duration, easing } : config,
      delay,
      enter,
      from,
      leave,
      onDestroyed,
    })

    const Component = animated(as)

    return transitions((styles, item) => item && <Component style={styles} ref={ref} {...props} />)
  }
)

export default Transition
