import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'

const QueryClientProvider = ({ children, client: clientIn }) => {
  const [client] = useState(() => {
    const queryClient = clientIn || new QueryClient()
    const { queries = {} } = queryClient.getDefaultOptions()

    queryClient.setDefaultOptions({
      queries: {
        ...queries,
        meta: {
          ...queries.meta,
          queryClient,
        },
      },
    })

    return queryClient
  })

  return <ReactQueryClientProvider client={client}>{children}</ReactQueryClientProvider>
}

export default QueryClientProvider
